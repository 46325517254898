 const  subcourses = [
    {
        "_id": "64e12a1acafd13d5c2ad46fb",
        "name": "Java Essentials",
        "description": "The course starts with project structure of java application.&nbsp; <br /><br /> Module 1 covers access specifiers of classes and methods.&nbsp; <br /><br /> Module 2 covers JVMs memory (Heap and Stack) along with variable types and method signatures.&nbsp; <br /><br /> Module 3 dives into primitive data types, its assignments and casting to each other.&nbsp; <br /><br /> Module 4 focuses on statements and loops. if-else ladder, switch-case, for and while are covered in the module.&nbsp;<br /><br /> ",
        "shortDescription": "The course covers core fundamentals of java programming. From classes and objects to primitive types and loops also covering heap and stack along the way.",
        "featured": false,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 500,
        "cfeezoom": null,
        "introVideoCode": "498288644",
        "keywords": "Java basics, Java Essentials, Java Getting Started",
        "technicalWeightage": "FOUNDATIONAL",
        "type": "free",
        "sequence": 1,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/207569/pexels-photo-207569.jpeg',
        'language': 'English'

    },
    {
        "_id": "64e12a4ecafd13d5c2ad46fe",
        "name": "Object Orientation",
        "description": "<p>Module-I starts with Inheritance concepts like <br>- Hiding of variables<br>- Overriding<br>- Overloading<br>- Polymorphism</p><p>Module-II covers various keywords like:<br>- final<br>- static<br>- abstract<br>It also covers <br>- Initializer and static blocks <br>- static imports</p><p>Module-III dives deep into Interfaces and Constructors and Encapsulation with sample case study for implementing the concepts covered in the course.&nbsp;</p><p><em>&quot;Simple path to mastering Java is to master Object Orientation.&quot;</em><br><em>- Imtiyaz Hirani, Trainer.</em></p>",
        "shortDescription": "The course covers core object oriented concepts like Inheritance, Polymorphism, Final, Static, Abstract, Interface, & Constructors.",
        "featured": true,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 1000,
        "cfeezoom": null,
        "introVideoCode": "502638773",
        "keywords": "Java Object Orientaed Programming, Java Oops, Inheritance, Polymorphism, interface",
        "technicalWeightage": "FOUNDATIONAL",
        "type": "free",
        "sequence": 2,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/270404/pexels-photo-270404.jpeg',
        'language': 'English'
    },
    {
        "_id": "64e12a5ecafd13d5c2ad4700",
        "name": "Java Arrays",
        "description": "<p>Module-I starts with 1D arrays<br>- Creation<br>- Iteration<br>- Searching + Sorting</p><p>Module-II covers 2D Arrays<br>- fixed size<br>- variable size<br>- anonymous</p><p>Module-III covers 3D Arrays<br>- fixed size<br>- variable size<br>- anonymous<br><br>&nbsp;you also learn to work with arrays of objects by doing a case study.&nbsp;</p><p><em>&quot;Arrays could be an important tool in logic building for programmers.&quot;</em><br><em>- Imtiyaz Hirani, Trainer.</em></p>",
        "shortDescription": "The course covers 1D, 2D and 3D arrays. It also dives into Searching, Sorting and Arrays of Objects.",
        "featured": true,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 500,
        "cfeezoom": null,
        "introVideoCode": "501160052",
        "keywords": "Java Arrays, 1D Array, 2D Array",
        "technicalWeightage": "FOUNDATIONAL",
        "type": "premium",
        "sequence": 3,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/207569/pexels-photo-207569.jpeg',
        'language': 'English'
    },
    {
        "_id": "64e12a6ecafd13d5c2ad4702",
        "name": "Generics and Collections Framework",
        "description": "<p>The course covers A-Z of Java Generics and Collections framework.</p><p>Topic wise details are:&nbsp;</p><p>List interface: ArrayList<br>Map interface: HashMap<br>Set interface: TreeSet, HashSet</p><p>Comparable and Comparator interfaces for feature sorting of objects.</p><p>overriding and overloading of Equals method.&nbsp;</p><p>Wildcard Operator(?) with its bounds.</p><p>Type Parameter class and method.</p>",
        "shortDescription": "The course covers A-Z of Java Generics and Collections Framwwork. Case studies are used to explain the concepts. All concepts are practically implemented.",
        "featured": true,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 2000,
        "cfeezoom": null,
        "introVideoCode": "502163108",
        "keywords": "Generics and Collections, Java, ArrayList, HashMap, TreeSet, Stacks,Queues",
        "technicalWeightage": "PROFESSIONAL",
        "type": "premium",
        "sequence": 4,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/1181671/pexels-photo-1181671.jpeg',
        'language': 'English'
    },
    {
        "_id": "64e12a7bcafd13d5c2ad4704",
        "name": "Java Streams, Lambdas and Exception Handling",
        "description": "<p>The course covers A-Z of Java Generics and Collections framework.</p><p>Topic wise details are:&nbsp;</p><p>List interface: ArrayList<br>Map interface: HashMap<br>Set interface: TreeSet, HashSet</p><p>Comparable and Comparator interfaces for feature sorting of objects.</p><p>overriding and overloading of Equals method.&nbsp;</p><p>Wildcard Operator(?) with its bounds.</p><p>Type Parameter class and method.</p>",
        "shortDescription": "The course covers popular lambda expressions and streams api that is widely used in java applications with appropriate real world scenarios and their implementation.",
        "featured": false,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 1500,
        "cfeezoom": null,
        "introVideoCode": "502163108",
        "keywords": "Java Streams and Lambda Expressions, Exception handling",
        "technicalWeightage": "PROFESSIONAL",
        "type": "premium",
        "sequence": 5,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/207569/pexels-photo-207569.jpeg',
        'language': 'English'
    },
    {
        "_id": "64e12a89cafd13d5c2ad4706",
        "name": "JUnit Testing and JDBC API",
        "description": "The course covers A-Z of Java Generics and Collections framework.</p><p>Topic wise details are:&nbsp;</p><p>List interface: ArrayList<br>Map interface: HashMap<br>Set interface: TreeSet, HashSet</p><p>Comparable and Comparator interfaces for feature sorting of objects.</p><p>overriding and overloading of Equals method.&nbsp;</p><p>Wildcard Operator(?) with its bounds.</p><p>Type Parameter class and method.</p>",
        "shortDescription": "The course covers fundamentals of JUnit testing framework and dwells into appropriate ways of testing methods having core process logic. Covers both JUnit 4 and JUnit 5. <br /> In second part of the course, you learn how to connect to external Database from Java applications and using popular JDBC API, you will also do practicals for CRUD data operations.",
        "featured": false,
        "trainerId": "64e05da2b505b466e46eb8c9",
        "fee": 1200,
        "cfeezoom": null,
        "introVideoCode": "502163108",
        "keywords": "JUnit, JDBC",
        "technicalWeightage": "PROFESSIONAL",
        "type": "premium",
        "sequence": 6,
        "trackId": "64e13322b617710fd139a70e",
        "__v": 0,
        'img': 'https://images.pexels.com/photos/207569/pexels-photo-207569.jpeg',
        'language': 'English'
    }
]; 

export default subcourses; 